import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
//Firebase
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFirestoreModule } from 'angularfire2/firestore';
//Routing
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//Components
import { ProjectListComponent } from './project-list/project-list.component';
import { SignInComponent } from './sign-in/sign-in.component';
import * as $ from 'jquery';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { UserListComponent } from './user-list/user-list.component';
import { SetupPasswordComponent } from './setup-password/setup-password.component';
//Icon
import { AngularFontAwesomeModule } from 'angular-font-awesome';
//Bootstrap
import { NgbModule, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './modal/delete-project/confirm-modal.component';
import { EditUserModalComponent } from './modal/edit-user/edit-user-modal.component';
import { DeleteUserModalComponent } from './modal/delete-user/delete-user-modal.component';
import { InviteUserModalComponent } from './modal/invite-user/invite-user-modal.component';
import { UserInvitationListComponent } from './user-invitation-list/user-invitation-list.component';
import { DeleteInvitationUserModalComponent } from './modal/delete-invitation-user/delete-invitation-user-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
//DeviceDetector
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RouteGuard } from './modules/core/services/guard/route-guard';
import { AppLoadService } from './modules/core/services/app-load-service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SetupPasswordGuard } from './modules/core/services/guard/setup-password-guard';
import { InvalidUrlGuard } from './modules/core/services/guard/invalid-url-guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordGuard } from './modules/core/services/guard/forgot-password-guard';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginGuard } from './modules/core/services/guard/login-guard';
import { UploadFormComponent } from './upload-form/upload-form.component';
import { DropZoneDirective } from './drop-zone.directive';
import { DeviceDetectorModule } from 'ngx-device-detector';
import * as algoliasearch from 'algoliasearch';

export function get_user(appLoadService: AppLoadService) {
  return () => appLoadService.getUser();
}
import { ReversePipe } from './reverse.pipe';
import { AppSettings } from './modules/core/services/settings';
import { ProjectDetailGuard } from './modules/core/services/guard/project-detail-guard';
import { AuthService } from './modules/core/services/auth/auth.service';

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBVcp02QvaSAbUEuy-6K2jMLgpgPRIVpcU",
    authDomain: "myappstorage-e4194.firebaseapp.com",
    databaseURL: "https://myappstorage-e4194.firebaseio.com",
    projectId: "myappstorage-e4194",
    storageBucket: "myappstorage-e4194.appspot.com",
    messagingSenderId: "217363765659"
  }
};


@NgModule({
  declarations: [
    AppComponent,
    ProjectListComponent,
    ProjectDetailComponent,
    AddProjectComponent,
    UserListComponent,
    SetupPasswordComponent,
    SignInComponent,
    ConfirmModalComponent,
    SignInComponent,
    ProjectDetailComponent,
    EditUserModalComponent,
    DeleteUserModalComponent,
    InviteUserModalComponent,
    ErrorPageComponent,
    UserInvitationListComponent,
    DeleteInvitationUserModalComponent,
    SpinnerComponent,
    ReversePipe,
    ForgotPasswordComponent,
    UploadFormComponent,
    DropZoneDirective
  ],
  imports: [
    NgbModule.forRoot(),
    NgSelectModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    CommonModule,
    FormsModule,
    SweetAlert2Module,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    Ng2DeviceDetectorModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }), // ToastrModule added
    ReactiveFormsModule
  ],

  providers: [NgbActiveModal,
    RouteGuard,
    AppSettings,
    AppLoadService,
    SetupPasswordGuard,
    InvalidUrlGuard,
    ForgotPasswordGuard,
    LoginGuard,
    ProjectDetailGuard,
    AuthService,
    { provide: APP_INITIALIZER, useFactory: get_user, deps: [AppLoadService], multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
