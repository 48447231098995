import { Component } from '@angular/core';
import { Location } from '@angular/common';
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { Ng2DeviceService } from 'ng2-device-detector';
import { Router, NavigationStart, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
//Firebase
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable, Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { AppSettings } from './modules/core/services/settings';
import { AuthService } from './modules/core/services/auth/auth.service';
import { httpStatus } from '../app/constants';
import { AppLoadService } from './modules/core/services/app-load-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  deviceInfo = {};
  title = 'MobileCentral';
  isShowNavbar = false;
  displayName = '';
  currentUrl = '';
  userInformationSubscription: Subscription;
  navBarSubscription: Subscription;
  activeTab = '';
  error = {
    isError: false,
    errorMsg: ''
  };

  constructor(private settings: AppSettings, public afAuth: AngularFireAuth, public router: Router, private deviceService: Ng2DeviceService,
    private db: AngularFirestore, private route: ActivatedRoute, private authService: AuthService, private location: Location) { }

  ngOnInit() {
    this.detectDevice();

    this.afAuth.auth.onAuthStateChanged(user => {
      if (user) {
        // this.authService.reloadAuthSessionTimer()
        this.initUserInformation();
      }
      else {
        // Clear user data
        console.log('clearData)')
        this.authService.setUser(null)
        this.authService.setUserInformation(null)
        this.authService.setToken('')
        if(this.userInformationSubscription != null){
          this.userInformationSubscription.unsubscribe()
        }
        
        this.authService.stopAuthSessionTimer()
        this.navigateToLogin();
      }
    })

    this.settings.getObservableIsShowNavbar().subscribe(b => {
      this.isShowNavbar = b;
    })
  }

  initUserInformation() {
    
    this.userInformationSubscription = this.db.collection('user').doc(this.authService.getUser().uid).snapshotChanges().subscribe(userInformation => {
      this.authService.setUserInformation(userInformation.payload.data())
      this.displayName = this.authService.getUserInformation().username;
      console.log(this.displayName, this.authService.getUserInformation());
      
      this.checkUserStatus();
    });
  }

  checkUserStatus() {
    if (this.authService.getUserInformation().status.isActivated === false) {
      swal({
        type: 'error',
        title: 'Oops...',
        text: 'Your account has been disabled by administrator',
        showCancelButton: false,
        confirmButtonText: 'OK'
      }).then((result) => {
        this.logout();
      })
    }
  }

  getUserRole() {
    console.log(this.authService)
    return this.authService.getUser().role
  }

  getDisplayName() {
    return this.authService.getUserInformation().username;
  }
  gotoProjectList() {
    this.router.navigate(['/projectList']);
  }
  gotoUserList() {
    this.router.navigate(['/userList']);
  }

  gotoInvitationList() {
    this.router.navigate(['/userInvitationList']);
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }
  // setUserRole() {
  //   this.db.collection('/user', ref => ref.where('username', '==', this.displayName)).valueChanges().subscribe(item => {
  //     //console.log(item);
  //     if (item) {
  //       var user = item[0];
  //       User.userInformation.role = user['role'];
  //     }
  //   });
  // }

  setActiveTab(tab) {
    // Get the toast DIV
    var active = document.getElementById(tab);
    // Add the "show" class to DIV
    active.className = "show";
  }

  logout() {
    this.afAuth.auth.signOut()
  }

  alertErrorMessage(msg) {
    console.log(msg);
    this.error = {
      isError: true,
      errorMsg: msg
    }
    // x.alert();
    //alert(msg);
  }

  closeError() {
    this.error = {
      isError: false,
      errorMsg: ''
    }
  }

  detectDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  getDetectDevice() {
    return this.deviceInfo;
  }

  navigateBack() {
    console.log('sdfsdfsd');

    this.location.back();
  }

}


