import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BASE_URL, SERVICE_API,httpOptions } from '../../../../constants'

import { AngularFireAuth } from 'angularfire2/auth';
import { timer, Subscription } from '../../../../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user
  private userInformation
  private token
  private authSessionTimerObservable: Subscription;
  private url = BASE_URL
  constructor(private http: HttpClient, private auth: AngularFireAuth) { }

  sendIdTokenToVerify(idToken) {
    var params = {
        idToken: idToken
    };

    let promise = new Promise((resolve, reject) => {
        this.http.post(this.url + SERVICE_API.verifyIdToken, params, httpOptions)
          .toPromise()
          .then(
            res => { // Success
              console.log(res)
              if(res['status'] === 'SUCCESS'){
                this.updateIdToken()
              }
              else{
                this.auth.auth.signOut()
              }
              resolve(res)
              
            }
          )
          .catch((err) => {
            resolve(err)
          })
      })
      return promise
  }

  getIdToken() {
    let promise = new Promise((resolve, reject) => {
        this.auth.idToken.subscribe(idToken => {
            resolve(idToken)
        })
    })
    return promise
  }

  updateIdToken() {
    this.auth.idToken.subscribe(idToken => {
      console.log('updateToken')
      this.token = idToken
      console.log(this.token)
    })
  }

  // startAuthSessionTimer(){
  //   this.authSessionTimerObservable = timer(2000, 1000).subscribe(t=> {
  //    console.log(t)
  //    if(t%10 === 0){
  //     console.log('5 seconds')
  //     this.token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImI4OWY3MzQ2YTA5ODVmNDIxZGNkOGQzMGMwYjMwZWViZmFlMTlhMWUifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbXlhcHBzdG9yYWdlLWU0MTk0IiwiYXVkIjoibXlhcHBzdG9yYWdlLWU0MTk0IiwiYXV0aF90aW1lIjoxNTMyNDI4MDYwLCJ1c2VyX2lkIjoiYUk0YTNnSnBHbmR5YlBDbGtLSllNdXV3Q0pGMiIsInN1YiI6ImFJNGEzZ0pwR25keWJQQ2xrS0pZTXV1d0NKRjIiLCJpYXQiOjE1MzI0OTExMDAsImV4cCI6MTUzMjQ5NDcwMCwiZW1haWwiOiJra2xpbnNhbmdAbWFuYW9zb2Z0d2FyZS5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsia2tsaW5zYW5nQG1hbmFvc29mdHdhcmUuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.SLMsyArraBQ80kRF2UaenJSvjTaySjsy3pWcrT43TgQAh_JvJ5U2rus8-unMyVBpatBlKEXhj9isl5mqK3z0IFu1YHukKoLPANllJcsAdhdkuD6ir_NzHetgUFgBaCudb4sR9fLbkqTQFQM9lavACHFuC9JQ8b_yJyUBWmvxN7oZnDoyH79ZthzGleNZROuzmU3XqhuWF8CLmBPwuBN_qgriZ5Oi0ryz-UYh1NVVpxtz0X7nuwx3N0ImF7oBTcCU83FcoQu80wQinqyHVUYvjn6ZeX0rhUnwpOvLaRlomDCUYvl-RymTPJ7NG-QtWJQT_0iAhTWqyk4NwT37Z9VDfQ'
  //     this.sendIdTokenToVerify(this.token)
  //    }
  //  })
  // }

  stopAuthSessionTimer(){
    if(this.authSessionTimerObservable){
      this.authSessionTimerObservable.unsubscribe()
    }
  }

  // reloadAuthSessionTimer(){
  //   this.auth.idToken.subscribe(token => {
  //     this.token = token
  //     this.authSessionTimerObservable.unsubscribe()
  //     this.startAuthSessionTimer()
  //   })
  // }

  getToken(){
    return this.token
  }

  setToken(token){
    this.token = token
  }

  setUserInformation(userInformation){
      this.userInformation = userInformation
  }

  getUserInformation(){
      return this.userInformation
  }

  setUser(user){
    this.user = user
  }

  getUser(){
    return this.user
  }
}
