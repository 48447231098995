import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable()
export class InvalidUrlGuard implements CanActivate {

  constructor(public router: Router) {}

  public canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
        resolve(true);
        this.router.navigate(['/error-page'])
        return false
    })
  }
}