import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettings } from '../modules/core/services/settings';
import swal from 'sweetalert2';
import { Subscription } from '../../../node_modules/rxjs';
import { AngularFirestore } from '../../../node_modules/angularfire2/firestore';
import { Location } from '../../../node_modules/@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../app.component';
import { ViewChild } from '@angular/core';
import { UserService } from '../user-services/user.service';
import * as algoliasearch from 'algoliasearch';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
//Provider
import { ProjectDetailService } from './project-detail.service';
import { AuthService } from '../modules/core/services/auth/auth.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent implements OnInit {
  userData = this.authService.getUserInformation();
  projectDetail = {}
  projectAsset = {}
  loadingData = true
  projectId
  iOSAsset = []
  AndroidAsset = []
  projectSubscription: Subscription
  projectAssetSubscription: Subscription
  deviceInfo = null;
  isAssetAvailable = false;
  downloadTitleText;
  isMobile = false;
  isPlatformMatch = false;
  isPlatformiOS = false;
  isPlatformAndroid = false;
  selectAndroidVersion = {
    key: "Select version..",
    value: "none"
  }
  selectiOSVersion = {
    key: "Select version..",
    value: "none"
  }
  isiOSAlreadySelectVersion = false;
  isAndroidAlreadySelectVersion = false;
  enableEdit = false;
  warningMessage = {
    requiredName: false
  }
  loadingUpdateData = false;
  projectName = '';
  //For search users; Algolia
  client: any;
  index: any;
  searchField: FormControl = new FormControl({ value: '', disabled: true });
  // Manual serach
  userList: any;
  searchResult = [];
  sharedUserList = [];
  sharedAll: boolean;
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private route: ActivatedRoute, private settings: AppSettings, private location: Location, private deviceService: DeviceDetectorService, private router: Router,
    private appComponent: AppComponent, private userService: UserService, private projectDetailService: ProjectDetailService, private authService: AuthService) {
    this.route.params.subscribe(params => {
      this.projectId = params.id
      this.getProject();
    });

    //Init Algolia
    // this.client = algoliasearch('JMM16XE73I', 'edd548fdcbf96600249b9b5d45442f68');
    // this.index = this.client.initIndex('user');
    // this.setUpAlgolia();
  }

  ngOnInit() {
    this.settings.setShowNavbar(true)
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.downloadTitleText = "Download Installer Here"
    this.selectAndroidVersion.key = "Select version.."
    this.selectiOSVersion.key = "Select version.."

    if (this.deviceInfo.device === 'iphone' || this.deviceInfo.device === 'android') {
      this.isMobile = true;
    }
  }

  ngOnDestroy() {
    this.removeListenerOnProjectData();
  }

  getProject() {
    let promiesProject = this.projectDetailService.getProject(this.projectId);
    let promiseProjectAsset = this.projectDetailService.projectAsset(this.projectId);

    Promise.all([promiesProject, promiseProjectAsset]).then(result => {
      if (result[0]) {
        console.log('promiesProject', result[0].data())
        this.projectDetail = result[0].data();
        this.projectName = this.projectDetail['name'];
        this.projectDetail['created_time_readable_format'] = new Date(result[0].data().created_time.seconds * 1000)

        this.sharedAll = this.projectDetail['sharedAll'];
        if (this.sharedAll) {
          this.sharedUserList = [];
        } else {
          this.sharedUserList = this.projectDetail['grant_uid'];
        }
        this.initListenerOnProjectData()
      }
      // else {
      //   this.showProjectHasRemovedDialog();
      // }

      if (typeof result[1].data() !== 'undefined') {
        console.log('promiesProjectAsset', result[1].data())
        this.isAssetAvailable = true;

        //------------------------------- IOS ----------------------------------
        if (typeof result[1].data().iOS !== 'undefined') {
          let iosAssetListWithUploadDate = [];
          Object.keys(result[1].data().iOS).map(key => {
            let data = {
              key: key,
              value: result[1].data().iOS[key]
            };
            /// There old project assets that are not included uploaded time
            /// So we have to split into 2 arrays 
            /// Then concat those arrays
            if (result[1].data().iOS[key]['uploaded_date']) {
              iosAssetListWithUploadDate.push(data)
            } else {
              this.iOSAsset.push(data)
            }
          })

          if (iosAssetListWithUploadDate.length > 0) {
            //Sort the assets by uploaded_update 
            iosAssetListWithUploadDate.sort(function (a, b) {
              return a.value.uploaded_date - b.value.uploaded_date;
            });
          }
          //Reverse the arrays
          this.iOSAsset = this.iOSAsset.reverse()
          iosAssetListWithUploadDate = iosAssetListWithUploadDate.reverse()
          //Concat them
          this.iOSAsset = iosAssetListWithUploadDate.concat(this.iOSAsset)

          if (this.deviceInfo.device === 'iphone') {
            this.isPlatformiOS = true;
            this.isPlatformMatch = true;
            this.downloadTitleText = "Download iOS Installer Here";
          }
        }
        else if (this.deviceInfo.device === 'iphone') {
          this.downloadTitleText = "The ipa files is not available for this time";
        }

        //------------------------------- ANDROID ----------------------------------
        if (typeof result[1].data().Android !== 'undefined') {
          let androidAssetListWithUploadDate = [];
          Object.keys(result[1].data().Android).map((key) => {
            let data = {
              key: key,
              value: result[1].data().Android[key]
            };

            /// There old project assets that are not included uploaded time
            /// So we have to split into 2 arrays 
            /// Then concat those arrays
            if (result[1].data().Android[key]['uploaded_date']) {
              androidAssetListWithUploadDate.push(data)
            } else {
              this.AndroidAsset.push(data)
            }
          })

          if (androidAssetListWithUploadDate.length > 0) {
            //Sort the assets by uploaded_update 
            androidAssetListWithUploadDate.sort(function (a, b) {
              return a.value.uploaded_date - b.value.uploaded_date;
            });
          }
          //Reverse the arrays
          this.AndroidAsset = this.AndroidAsset.reverse()
          androidAssetListWithUploadDate = androidAssetListWithUploadDate.reverse()
          //Concat them
          this.AndroidAsset = androidAssetListWithUploadDate.concat(this.AndroidAsset)

          if (this.deviceInfo.device === 'android') {
            this.isPlatformAndroid = true;
            this.isPlatformMatch = true;
            this.downloadTitleText = "Download Android Installer Here";
          }
        }
        else if (this.deviceInfo.device === 'android') {
          this.downloadTitleText = "The apk files is not available for this time";
        }
      }

      if (this.deviceInfo.device !== 'iphone' && this.deviceInfo.device !== 'android') {
        this.downloadTitleText = "Download Installer Here";
        this.isPlatformMatch = true;
      }
      this.loadingData = false
    })
  }

  initListenerOnProjectData() {
    this.projectSubscription = this.projectDetailService.listenerOnProject().subscribe(snapshot => {
      if (snapshot.length > 0 && this.projectId === snapshot[0].payload.doc.data()['id']) {
        if (snapshot[0].type === 'modified') {
          document.getElementById("toast").className = "show"
        }
        else if (snapshot[0].type === 'removed') {
          this.showProjectHasRemovedDialog();
        }
      }
    })

    this.projectAssetSubscription = this.projectDetailService.listenerOnProjectAsset().subscribe(snapshot => {
      if (snapshot.length > 0 && this.projectId === snapshot[0].payload.doc.data()['id']) {
        if (snapshot[0].type === 'modified') {
          document.getElementById("toast").className = "show"
        }
        else if (snapshot[0].type === 'removed') {
          document.getElementById("toast").className = "show"
        }
      }
    })
  }

  removeListenerOnProjectData() {
    this.projectSubscription.unsubscribe()
    this.projectAssetSubscription.unsubscribe();
  }



  showProjectHasRemovedDialog() {
    swal({
      type: 'error',
      title: 'Oops...',
      text: 'This project has no longer exists!',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
    }).then(() => {
      this.location.back();
    })
  }

  selectiOSAsset(asset) {
    this.selectiOSVersion = asset
    this.isiOSAlreadySelectVersion = true;
    // var url = asset.value.installer_url
    // console.log("url", url)
  }

  selectAndroidAsset(asset) {
    this.selectAndroidVersion = asset
    this.isAndroidAlreadySelectVersion = true;
    // console.log(asset.value.installer_url)
  }

  uploadInstaller(isiOS) {
    this.router.navigate(['/projectList/projectDetail/uploadForm/' + (isiOS ? 'ios' : 'android') + '/' + this.projectId + '/' + this.projectDetail['name']])
  }

  downloadiOS() {
    var link = document.createElement("a"); link.download = "a"; link.href = "itms-services://?action=download-manifest&url=" + this.selectiOSVersion.value['installer_url']; document.body.appendChild(link); link.click();
  }

  downloadAndroid() {
    // console.log(this.selectAndroidVersion)
    var link = document.createElement("a"); link.download = "a"; link.href = this.selectAndroidVersion.value['installer_url']; document.body.appendChild(link); link.click();
  }

  getMonth(month) {
    return this.months[month];
  }

  getOrdinalSuffix(number) {
    var j = number % 10,
      k = number % 100;
    if (j == 1 && k != 11) {
      return "st";
    }
    if (j == 2 && k != 12) {
      return "nd";
    }
    if (j == 3 && k != 13) {
      return "rd";
    }
    return "th";
  }

  getUploadedDate(date) {

    let dateString = "";
    let timeString = "";

    if (date) {
      date = new Date(date);
      let dateNumber = date.getDate();
      let hours = date.getHours();
      var ampm = hours >= 12 ? 'pm' : 'am';
      //March 15th 2019, 2:23:33 pm
      dateString = this.getMonth(date.getMonth() + 1) + " " + dateNumber + this.getOrdinalSuffix(dateNumber) + " " + date.getFullYear();
      timeString = hours + ":" + date.getMinutes() + ":" + date.getMilliseconds() + " " + ampm;

      return " - " + dateString + ", " + timeString
    }

    // console.log(date, dateString, timeString)

    return "";
  }

  editProjectDetail() {
    this.enableEdit = true;
    this.getAllUser();
    //subscribe searchField
    this.subscribeSearchField();
  }

  subscribeSearchField() {
    this.searchField.enable();
    this.searchField.valueChanges.pipe(debounceTime(200)).subscribe(val => {
      console.log(val);
      if (val !== '') {
        this.onSearchText(val);
      } else {
        this.searchResult = [];
      }
    })
  }

  onSearchText(text) {
    const result = this.userList.filter(word => word['sort_username'].includes(text));
    this.searchResult = result;
    console.log(result);
  }

  selectUser(user) {
    this.sharedUserList.push({ uid: user.uid, username: user.username });
    this.searchField.setValue('');
    console.log(this.sharedUserList);
  }

  removeSharedUser(index) {
    this.sharedUserList.splice(index, 1);
    console.log(this.sharedUserList);
  }

  isSharedAll(sharedAll) {
    this.sharedAll = sharedAll;
    if (this.sharedAll) {
      this.sharedUserList = [];
    }
  }

  getAllUser() {
    this.userService.getAllUsers().valueChanges().subscribe(user => {
      this.userList = user;
    });
  };

  setUpAlgolia() {
    this.index.setSettings({
      'searchableAttributes': [
        'sort_username'
      ]
    }, function (err, content) {
      console.log(content);
    });
  }

  onSubmitProjectDetail(detail) {
    console.log(this.projectDetail);
    if (this.projectDetail['name']) {
      this.loadingUpdateData = true;

      this.projectDetailService.onSubmitProjectDetail(this.projectDetail, this.sharedAll, this.sharedUserList)
        .then(() => {
          console.log("Document successfully updated!");
          window.location.reload();
          // this.router.navigate(['projectList']);
        })
        .catch((error) => {
          this.loadingUpdateData = false;
          this.appComponent.alertErrorMessage(error.error.message);
        });
    } else {
      this.warningMessage.requiredName = true;
    }
  }
}
