import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//Service
import { ProjectListService } from '../../project-list/project-list-service.service';
import { ProjectListComponent } from '../../project-list/project-list.component';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  modalTitle = '';
  modalBody = '';
  deleteProgressing: boolean = false;
  @Output() progressEvent = new EventEmitter<string>();

  @Input()
  pid: any;

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private projectListService: ProjectListService, private appComponent: AppComponent) { }

  ngOnInit() {
  }

  openModal(content, title, body) {
    console.log(this.pid);
    this.modalService.open(content).result.then((result) => {
      this.removeProject(this.pid);
    }, (reason) => {
      console.log("Cancel delete");
    });;
    this.modalTitle = title;
    this.modalBody = body;
  }

  removeProject(pid) {
    //console.log(pid);
    this.deleteProgressing = true;
    this.progressEvent.emit(this.deleteProgressing.toString());
    this.projectListService.removeProject(pid).then((data) => {
      console.log(data);
      if (data['status'] === 'SUCCESS') {
        this.projectListService.removeProjectAsset(pid).then((data) => {
          console.log(data);
          if (data['success'] === true) {
            this.ngOnInit();
            this.deleteProgressing = false;
          } else {
            this.deleteProgressing = false;
            this.appComponent.alertErrorMessage(data['message']);
          }
          this.progressEvent.emit(this.deleteProgressing.toString());
        })
          .catch((error) => {
            console.log(error);
            
            this.deleteProgressing = false;
            this.progressEvent.emit(this.deleteProgressing.toString());
          })
      } else {
        this.deleteProgressing = false;
        this.appComponent.alertErrorMessage(data['message']);
      }

      this.progressEvent.emit(this.deleteProgressing.toString());
    })
      .catch((error) => {
        this.deleteProgressing = false;
        this.progressEvent.emit(this.deleteProgressing.toString());
      })
  }
}
