import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProjectDetailService {

  constructor(private http: HttpClient, private db: AngularFirestore) { }

  getProject(projectId) {
    return this.db.collection('project').doc(projectId).ref.get()
  }

  projectAsset(projectId) {
    return this.db.collection('projectAsset').doc(projectId).ref.get()
  }

  listenerOnProject() {
    return this.db.collection('project').stateChanges(['modified', 'removed'])
  }

  removeListenerOnProject() {
    return this.db.collection('project').stateChanges(['modified', 'removed']);
  }

  listenerOnProjectAsset() {
    return this.db.collection('projectAsset').stateChanges(['modified', 'removed'])
  }

  onSubmitProjectDetail(projectDetail, sharedAll, sharedUserList) {
    return this.db.collection("project").doc(projectDetail['id']).update({
      "name": projectDetail['name'],
      "description": projectDetail['description'],
      "sharedAll": sharedAll,
      "grant_uid": sharedUserList
    })
  }
}
