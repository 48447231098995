import { Component, OnInit } from '@angular/core'
//Firebase
import { AngularFirestore } from 'angularfire2/firestore'
import { NgxSpinnerService } from 'ngx-spinner'
import { UserService } from "../user-services/user.service"
import { AppComponent } from '../app.component' 
//Constants
import { httpStatus } from '../../app/constants.js';
import { AppSettings } from '../modules/core/services/settings';

export interface UserList { uid: string, username: string, role: string, status:{isActivated:boolean,updated_time:number}}
export interface RoleList { id: string, role_name: string }
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  ORDER_USERNAME_FIELD = 'sort_username'
  ORDER_UPDATED_TIME_FIELD = 'status.updated_time'
  ORDER_IS_ACTIVATED_FIELD = 'status.isActivated'
  ORDER_ASC = 'asc'
  ORDER_DESC = 'desc'

  userCount = 0
  totalPage = 0
  page = 1
  limit = 10
  startItem: any
  lastItem: any
  index = this.limit
  userRole: any
  displayName: any
  closeResult: string
  userList: any
  indexItem = []
  orderBy: any = this.ORDER_DESC
  sortBy = this.ORDER_UPDATED_TIME_FIELD
  loadingData = false
  isUpdateTable = true
  deleteProgressing: boolean;

  

  constructor(private db: AngularFirestore,private appComponent: AppComponent,private userService:UserService,private spinner: NgxSpinnerService, private settings:AppSettings) {
    this.displayName = appComponent.getDisplayName()
    this.userRole = appComponent.getUserRole()
    this.snapshotChangesTable()
  }

  changeUserStatus($event,user) {
    this.spinner.show()
    $event.srcElement.disabled = true
    this.userService.changeUserStatus(user.uid).then((data) => {
      this.spinner.hide()
      $event.srcElement.disabled = false
    }).catch((err) => {
      this.spinner.hide()
      $event.srcElement.disabled = false
      $event.srcElement.checked = !$event.srcElement.checked
    })
  }
  
  ngOnInit() {
    this.indexItem = [];
    this.page = 1;
    this.index = this.limit;
    this.isUpdateTable = false;
    this.settings.setShowNavbar(true)
    this.getUserList(this.page, this.limit, this.orderBy, this.sortBy, undefined, false)
  }

  //Firebase SDK
  getUserList(page,limit,orderBy,sortBy,item, isNext) {
    this.loadingData = true
    this.userService.getUserList(page,limit,orderBy,sortBy,item).then(res => {
      let items = []
      if (res['status'] == httpStatus.success) {
        res['item'].forEach(el => items.push(el.data as UserList))
        this.userList = items
        this.getUserCount()
        if (this.indexItem.length == 0) {
          this.indexItem.push({
            page: this.page,
            startItem: items[0],
            lastItem: items[items.length - 1]
          });
        } else if (isNext) {
          if (this.indexItem.map((item) => { return item.page }).indexOf(this.page) == -1) {
            this.indexItem.push({
              page: this.page,
              startItem: items[0],
              lastItem: items[items.length - 1]
            });
          }
        }
        this.loadingData = false
      } else {
        this.appComponent.alertErrorMessage(res['message'])
        this.loadingData = false
      }
    })
  }

  getUserCount() {
    var totalUser = this.db.doc('information/user')
    totalUser.valueChanges().subscribe(snapshots => {
      this.userCount = snapshots['userCount']
      this.totalPage = this.userCount % this.limit == 0 ? this.userCount / this.limit : (this.userCount / this.limit | 0) + 1
    })
  }
  snapshotChangesTable() {
    this.db.collection('user').stateChanges().subscribe(items => {
      items.forEach(el => {
        if(el.type === "added" && el.payload.doc.data()[this.ORDER_IS_ACTIVATED_FIELD] && !this.loadingData) {
          document.getElementById("toast").className = "show"
        }else if(el.type === "modified") {
          let changedItem = el.payload.doc.data()
          let adjustedChangedItem = {
            ...changedItem,
            status : {isActivated: changedItem[this.ORDER_IS_ACTIVATED_FIELD],updated_time: new Date(changedItem[this.ORDER_UPDATED_TIME_FIELD].seconds * 1000).toISOString()}
          }
          this.userList = this.userList.map(el => el.uid === changedItem["uid"] ? el = adjustedChangedItem : el)
          if(this.isUpdateTable) {
            document.getElementById("toast").className = "show"
          }
        }
        else if(el.type === "removed") {
          this.getUserList(this.page, this.limit, this.orderBy, this.sortBy, this.page > 1 ? this.getActualItemByFilterType() : undefined,false)
        }
      })
    })
  }

  getActualItemByFilterType() {
    if (this.sortBy === this.ORDER_USERNAME_FIELD) {
      return this.indexItem[this.page - 2].lastItem.username
    } else {
      return this.indexItem[this.page - 2].lastItem.status.updated_time
    }
  }

  onPrev() {
    if (this.page === 1) {
      return
    }
    this.index -= this.limit
    this.page--
    this.getUserList(this.page, this.limit, this.orderBy, this.sortBy, this.setItemWhenChangePage(this.indexItem[0],false),false)
    }

  onNext() {
    if (this.index >= this.userCount || this.userCount < this.index || this.userCount == 0) {
      return
    }
    this.index += this.limit
    this.page++
    this.getUserList(this.page, this.limit, this.orderBy, this.sortBy, this.setItemWhenChangePage(this.indexItem[this.page - 2],true),true)
  }

  setItemWhenChangePage(item,isNext) {
    switch (this.sortBy) {
      case this.ORDER_USERNAME_FIELD:
        return isNext ? item.lastItem.username : item.startItem.username
      case this.ORDER_IS_ACTIVATED_FIELD:
      return isNext ? {
          updatedTime: item.lastItem.updated_time,
          isActivated: item.lastItem.status.isActivated
        } : {
          updatedTime: item.startItem.updated_time,
          isActivated: item.startItem.status.isActivated
        }
      case this.ORDER_UPDATED_TIME_FIELD:
      return isNext ? item.lastItem.status.updated_time : item.startItem.status.updated_time
    }
  }


  updateOrderField(field) {
    let prevSortBy = this.sortBy
    this.sortBy = field
    if (this.sortBy === this.ORDER_USERNAME_FIELD) {
      this.isUpdateTable = false
      if (this.sortBy == prevSortBy) {
        this.orderBy = this.orderBy == this.ORDER_ASC ? this.ORDER_DESC : this.ORDER_ASC
      } else {
        this.orderBy = this.ORDER_ASC
      }

    } else {
      this.isUpdateTable = true
      if (this.sortBy == prevSortBy) {
        this.orderBy = this.orderBy == this.ORDER_DESC ? this.ORDER_ASC : this.ORDER_DESC
      } else {
        this.orderBy = this.ORDER_DESC
      }
    }
    this.ngOnInit()
  }


}
