import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BASE_URL, SERVICE_API, httpOptions } from '../../app/constants.js'


@Injectable({
  providedIn: 'root'
})
export class SetupPasswordService {

  url = BASE_URL
  constructor(private http: HttpClient) { }

  savePassword(invitationId,password) {
    var params = {
      invitationId: invitationId,
      password: password
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.url + SERVICE_API.savePassword, params,httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          }
        ).catch((err) => {
          reject(err)
        })
    })
    return promise
  }
}
