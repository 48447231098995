import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { Router, ActivatedRoute } from "@angular/router"
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage'
import { AngularFirestore } from 'angularfire2/firestore'
import { UserService } from "../user-services/user.service"
import { Observable } from 'rxjs'
import { PLATFORM, UPLOAD_PROJECT_KEY } from '../../app/constants.js';
@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent implements OnInit {
  @ViewChild("versionInputTextField") versionInputTextField: ElementRef;
  @ViewChild("descInputTextField") descInputTextField: ElementRef;
  buildFile: File
  plistFile: File
  projectId: string
  projectName: string
  versionInput: string
  descInput: string
  isAndroid: boolean
  isIOS: boolean
  isSupportBuildType: boolean = true
  isPlistSupportType: boolean = true
  isVersionInputError: boolean = false
  isDuplicatedVersion: boolean = false
  isSelectedBuildFile: boolean = false
  isSelectedPlistFile: boolean = false
  isUploadingBuildFile: boolean = false
  isUploadingPlistFile: boolean = false
  isUploadedBuildFile: boolean = false
  isUploadedPlistFile: boolean = false
  isUploadedError: boolean = false
  uploadProjectKey = UPLOAD_PROJECT_KEY
  UPLOAD_APK_FILE_MSG = this.uploadProjectKey.UPLOAD_APK_FILE_MSG_DEFAULT
  UPLOAD_IPA_FILE_MSG = this.uploadProjectKey.UPLOAD_IPA_FILE_MSG_DEFAULT
  UPLOAD_PLIST_FILE_MSG = this.uploadProjectKey.UPLOAD_PLIST_FILE_MSG_DEFAULT

  // Main task 
  task: AngularFireUploadTask
  plistTask: AngularFireUploadTask
  snapshot: Observable<any>

  // Download URL
  downloadURL: Observable<string>

  // State for dropzone CSS toggling
  isHovering: boolean

  constructor(private storage: AngularFireStorage, private activatedRoute: ActivatedRoute, private db: AngularFirestore, private router: Router, private userService: UserService) {
    let platform = this.activatedRoute.snapshot.paramMap.get("platform")
    this.projectId = this.activatedRoute.snapshot.paramMap.get("id")
    this.projectName = this.activatedRoute.snapshot.paramMap.get("projectName")
    platform === PLATFORM.ios ? this.isIOS = true : this.isAndroid = true
  }

  toggleHover(event: boolean) {
    this.isHovering = event
  }

  onChangeVersion(versionInput) {
    this.isVersionInputError = false
    this.isDuplicatedVersion = false
    this.versionInput = versionInput.trim()
  }
  onChangeDescription(descInput) {
    this.descInput = descInput.trim()
  }
  cancelUploadFile() {
    this.isUploadedBuildFile = false
    this.isIOS && (this.isUploadedPlistFile = false)
    this.isVersionInputError = false
  }

  cancelUploadBuildFile() {
    if (!this.isSelectedBuildFile) { return }
    this.isSelectedBuildFile = false
    this.isIOS ? this.UPLOAD_IPA_FILE_MSG = this.uploadProjectKey.UPLOAD_IPA_FILE_MSG_DEFAULT : this.UPLOAD_APK_FILE_MSG = this.uploadProjectKey.UPLOAD_APK_FILE_MSG_DEFAULT
    this.cancelUploadFile()
  }
  cancelUploadPlistFile() {
    if (!this.isSelectedPlistFile) { return }
    this.isSelectedPlistFile = false
    this.UPLOAD_PLIST_FILE_MSG = this.uploadProjectKey.UPLOAD_PLIST_FILE_MSG_DEFAULT
    this.cancelUploadFile()
  }

  resetUploaded() {
    this.isDuplicatedVersion = false
    this.versionInput = ''
    this.versionInputTextField.nativeElement.value = ''
    this.descInputTextField.nativeElement.value = ''
    this.cancelUploadBuildFile()
    this.cancelUploadPlistFile()
  }

  cancelSelectedPlist() {
    if (!this.isSelectedPlistFile) { return }
    this.isSelectedPlistFile = false
  }

  chkDuplicateVersion() {
    if (this.versionInput) {
      this.db.collection('projectAsset').doc(this.projectId).valueChanges().subscribe((snapshot) => {
        if ((!this.isUploadingBuildFile || (this.isIOS && !this.isUploadingPlistFile)) && snapshot && snapshot[this.isIOS ? this.uploadProjectKey.PLATFORM_USER_VIEW_IOS : this.uploadProjectKey.PLATFORM_USER_VIEW_ANDROID][this.versionInput.replace('.', '\.')]) {
          this.isDuplicatedVersion = true
        }
      })
    }
  }

  selectBuildFile(event: FileList) {
    if (this.isSelectedBuildFile) { return }
    this.buildFile = event.item(0)
    this.isSupportBuildType = true
    this.isSelectedBuildFile = true
    this.isIOS ? this.UPLOAD_IPA_FILE_MSG = this.buildFile.name : this.UPLOAD_APK_FILE_MSG = this.buildFile.name
    if (this.buildFile.name.split('.')[1] !== (this.isIOS ? this.uploadProjectKey.UPLOAD_EXT_IOS : this.uploadProjectKey.UPLOAD_EXT_ANDROID)) {
      this.isSupportBuildType = false
      return
    }
  }
  selectPlistFile(event: FileList) {
    if (this.isSelectedPlistFile) { return }
    this.plistFile = event.item(0)
    this.isPlistSupportType = true
    this.isSelectedPlistFile = true
    this.UPLOAD_PLIST_FILE_MSG = this.plistFile.name
    if (this.plistFile.name.split('.')[1] !== this.uploadProjectKey.UPLOAD_EXT_PLIST) {
      this.isPlistSupportType = false
      return
    }
  }

  submitUpload() {
    if (!this.isSupportBuildType || !this.isSelectedBuildFile || !this.versionInput || this.isUploadedBuildFile || (this.isIOS && this.isUploadedPlistFile) || this.isDuplicatedVersion || (this.isIOS && !this.isPlistSupportType)) {
      if (!this.versionInput) {
        this.isVersionInputError = true
      }
      return
    }
    this.isUploadingBuildFile = true
    this.isUploadedError = false

    this.startUploadFile()
  }

  startUploadFile() {
    //Firestorage
    // let path = `${this.projectId}`
    // this.isIOS ? path += `/Installer/iOS/` : path += `/Installer/Android/`
    // path += `${this.versionInput}/${this.buildFile.name}`
    // // The main task
    // this.task = this.storage.upload(path, this.buildFile)

    // // storage
    // const ref = this.storage.ref(path)

    // // Progress monitoring
    // this.task.snapshotChanges()
    // this.task.percentageChanges().subscribe((percentage) => {
    //   document.getElementById("progressBar").style.width = this.isIOS ? (percentage/2).toString() + '%' : percentage.toString() + '%'
    // },(err) => {
    //   this.uploadedErrorAlert(err)
    // },() => {
    //   this.isIOS ? this.startUploadPlistFile() : this.getDownloadURL(ref)
    // })

    let uploadDetails = {
      projectId: this.projectId,
      platform: this.isIOS ? this.uploadProjectKey.PLATFORM_USER_VIEW_IOS : this.uploadProjectKey.PLATFORM_USER_VIEW_ANDROID,
      version: this.versionInput,
      apkFile: this.isIOS ? null : this.buildFile,
      ipaFile: !this.isIOS ? null : this.buildFile,
      plistFile: this.isIOS ? this.plistFile : null
    }
    console.log(uploadDetails)
    this.userService.uploadInstaller(this.isIOS, uploadDetails, res => {
      this.writeIntoFirestore(res.body.downloadUrl)
    }, error => {
      this.uploadedErrorAlert(error)
    }).subscribe((percentage) => {
      console.log(percentage)
      document.getElementById("progressBar").style.width = percentage.toString() + '%'
    })
  }

  startUploadPlistFile() {
    this.isUploadingBuildFile = false
    this.isUploadedBuildFile = true
    this.isUploadingPlistFile = true
    let path = `${this.projectId}/Installer/iOS/${this.versionInput}/${this.plistFile.name}`
    this.plistTask = this.storage.upload(path, this.plistFile)
    // storage
    const ref = this.storage.ref(path)
    // Progress monitoring
    this.plistTask.snapshotChanges()
    this.plistTask.percentageChanges().subscribe((percentage) => {
      document.getElementById("progressBar").style.width = (50 + percentage / 2).toString() + '%'
    }, (err) => {
      this.uploadedErrorAlert(err)
    }, () => {
      this.getDownloadURL(ref)
    })
  }

  getDownloadURL(ref) {
    this.downloadURL = ref.getDownloadURL()
    this.downloadURL.subscribe((url: string) => {
      this.writeIntoFirestore(url)
    }, (err) => {
      this.uploadedErrorAlert(err)
    })
  }
  writeIntoFirestore(url) {
    let projectAssetContent = {}

    this.isIOS ? projectAssetContent = {
      [this.uploadProjectKey.PLATFORM_USER_VIEW_IOS]: {
        [this.versionInput]: {
          installer_url: url, "description": this.descInput,
          uploaded_date: new Date().getTime()
        }
      }
    }
      :
      projectAssetContent = {
        [this.uploadProjectKey.PLATFORM_USER_VIEW_ANDROID]: {
          [this.versionInput]: {
            installer_url: url, "description": this.descInput,
            uploaded_date: new Date().getTime()
          }
        }
      }

    this.db.collection('projectAsset').doc(this.projectId).set(projectAssetContent, { merge: true }).then(() => {
      this.clearUploadingProgress()
      this.navigateToProjectDetail()
    }).catch((err) => {
      this.uploadedErrorAlert(err)
    })
  }

  uploadedErrorAlert(err) {
    this.clearUploadingProgress()
    this.isUploadedError = true
    console.log(err)
  }
  clearUploadingProgress() {
    this.isUploadedBuildFile = true
    this.isIOS && (this.isUploadedPlistFile = true, this.isUploadingPlistFile = false)
    this.isUploadingBuildFile = false
  }
  navigateToProjectDetail() {
    let that = this
    setTimeout(function () { that.router.navigate(["/projectList/projectDetail/" + that.projectId]) }, 3000);
  }


  disabledUploadBtn() {
    return !this.isSupportBuildType || !this.isSelectedBuildFile || !this.versionInput || this.isDuplicatedVersion || (this.isIOS && !this.isSelectedPlistFile)
  }

  disabledResetBtn() {
    return !this.isSelectedBuildFile || !this.versionInput || this.isUploadingBuildFile || (this.isIOS && this.isUploadingPlistFile) || (this.isIOS && !this.isSelectedPlistFile)
  }

  enabledUploadBtn() {
    return (this.isIOS ? !this.isUploadingBuildFile && !this.isUploadingPlistFile : !this.isUploadingBuildFile) && !this.isFinishedUploaded()
  }

  showVersionInputError() {
    if (this.isVersionInputError) {
      return this.uploadProjectKey.FORM_VERSION_EMPTY_MSG
    }
    if (this.isDuplicatedVersion && (!this.isUploadedBuildFile || (this.isIOS && !this.isUploadedPlistFile))) {
      return this.uploadProjectKey.UPLOAD_DUPLICATED_FILE_MSG
    }
  }

  isUploading() {
    return this.isIOS ? this.isUploadingBuildFile || this.isUploadingPlistFile : this.isUploadingBuildFile
  }

  isFinishedUploaded() {
    return (this.isIOS ? this.isUploadedBuildFile && this.isUploadedPlistFile : this.isUploadedBuildFile)
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }


  ngOnInit() {
  }

  goToUserDetailPage() {

  }


}
