import { AngularFireAuth } from "angularfire2/auth";
import { Injectable } from "@angular/core";
import { Observable, Subscription, timer } from "rxjs";
import { AngularFirestore } from "angularfire2/firestore";
import { AuthService } from "./auth/auth.service";

@Injectable()
export class AppLoadService {

  userInformationSubscription: Subscription;

  
  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore, private authService: AuthService) { }

  getUser(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(user => {
        if(user){
          console.log('AppLoadService: user is exists')
          this.authService.setUser(user)
          this.userInformationSubscription = this.db.collection('user').doc(user.uid).snapshotChanges().subscribe(userInformation => {
            this.authService.setUserInformation(userInformation.payload.data())
            console.log('AppLoadService: got an user information')
            // this.authService.startAuthSessionTimer()
            resolve(true);
          });

        }
        else{
          resolve(true);
        }
      })
    });
    return promise
  }
}