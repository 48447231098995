
import { Component, OnInit } from '@angular/core';
import { SignInModel } from './sign-in.model';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireStorage} from 'angularfire2/storage';
import { AngularFirestore } from 'angularfire2/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { AppSettings } from '../modules/core/services/settings';
import { AuthService } from '../modules/core/services/auth/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  submitted = false;
  signInModel = new SignInModel('', '')
  isEmailError = false;
  isPasswordError = false;
  emailErrorMessage = '';
  passwordErrorMessage = '';

  userRole: any;
  displayName = '';

  constructor(public settings:AppSettings, public afAuth: AngularFireAuth, private authService: AuthService, public routes: Router, private storage: AngularFireStorage, private db: AngularFirestore, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.settings.setShowNavbar(false)
    this.loadScript();
  }

  login(email, password) {
    this.spinner.show();
    this.afAuth.auth.signInWithEmailAndPassword(email, password).then(user => {
      // this.authService.getIdToken().then(idToken => {
      //   this.authService.setToken(idToken)
      //   this.submitted = false;
      //   this.spinner.hide();
      //   this.authService.setUser(user.user)
      //   this.initUserInformation()
      // })
      this.submitted = false;
        this.spinner.hide();
        this.authService.setUser(user.user)
        this.initUserInformation()
    }).catch(err => {
      this.spinner.hide();
      this.submitted = false;
      console.log(err)
      swal({
        type: 'error',
        title: 'Oops...',
        text: err.message
      })
    })
  }

  initUserInformation(){
    this.db.collection('user').doc(this.authService.getUser().uid).ref.get().then(userInformation => {
      console.log(userInformation.data())
      this.authService.setUserInformation(userInformation.data())
      this.routes.navigate(['/projectList'])
    });
  }

  navigateToForgotPassword(){
    this.routes.navigate(['/forgot-password'])
  }

  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/sign-in.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  setEmailError(message){
    this.isEmailError = true;
    this.emailErrorMessage = message;
  }

  setPasswordError(message){
    this.isPasswordError = true;
    this.passwordErrorMessage = message;
  }

  clearError(){
    this.isEmailError = false;
    this.isPasswordError = false;
    this.emailErrorMessage = ''
    this.passwordErrorMessage = ''
  }

  clearEmailError(){
    this.isEmailError = false;
    this.emailErrorMessage = ''
  }

  clearPasswordError(){
    this.isPasswordError = false;
    this.passwordErrorMessage = ''
  }

  onSubmit(signinForm) { 
    const email = signinForm.value.email;
    const password = signinForm.value.password;

    if(this.submitted){ return }
    
    if(!this.validateField(email, password)){ return }

    this.clearError();
    this.submitted = true; 
    this.login(email, password);
  }

  validateField(email, password): boolean{
    let isHaveInvalidField = false;
    if(!email || 0 === email.length || !this.validateEmail(email)){
      this.setEmailError('A valid email is required.')
      isHaveInvalidField = true;
    }

    if(!password || password.length < 6){
      this.setPasswordError('Password must be at least 6 characters.')
      isHaveInvalidField = true;
    }
    return !isHaveInvalidField
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


}
