import { HttpHeaders } from '@angular/common/http';
export const BASE_URL = 'https://asia-northeast1-myappstorage-e4194.cloudfunctions.net/';
export const MANAO_WEB_BASE_URL = 'https://manaoweb.company.manaosoftware.com:47895/';
export const SERVICE_API = {
    getProjectList: 'getProjectList',
    getProjectListForMobile: 'getProjectListForMobile',
    removeProject: 'removeProject',
    removeProjectAsset: 'deleteProjectAssets',
    getUserList: 'getUserList',
    sendInvitationMail: 'sendInvitationMail',
    savePassword: 'savePassword',
    removeUser: 'removeUser',
    changeUserStatus: 'changeUserStatus',
    changeProjectStatus: 'changeProjectStatus',
    removeInvitation: 'removeInvitation',
    getInvitationList: 'getInvitationList',
    verifyIdToken: 'verifyIdToken',
    uploadAndroidInstaller: 'uploadAndroidInstaller',
    uploadIOSInstaller: 'uploadIOSInstaller'
}

export const httpOptions = {
    headers: new HttpHeaders({
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET, POST, POST, DELETE',
        'Access-Control-Allow-Origin': 'http://localhost:4200/projectList',
        'Access-Control-Allow-Credentials': 'true'
    })
};

export const manaoHttpOptions = {
    headers: new HttpHeaders({
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Access-Control-Allow-Methods': 'GET, POST, POST, DELETE',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
}),
    observe: 'response',
    reportProgress: true
};

export const httpStatus = {
    success: 'SUCCESS'
};

export const PLATFORM = {
    ios: 'ios',
    android: 'android'
}


export const UPLOAD_PROJECT_KEY = {
    PLATFORM_USER_VIEW_IOS: 'iOS',
    PLATFORM_USER_VIEW_ANDROID: 'Android',
    UPLOAD_EXT_IOS: 'ipa',
    UPLOAD_EXT_ANDROID: 'apk',
    UPLOAD_EXT_PLIST: 'plist',
    UPLOAD_APK_FILE_MSG_DEFAULT: 'Upload a Apk File',
    UPLOAD_IPA_FILE_MSG_DEFAULT: 'Upload a Ipa File',
    UPLOAD_PLIST_FILE_MSG_DEFAULT: 'Upload a Plist File',
    UPLOAD_UNSUPPORT_EXT_MSG: 'Unsupported file type',
    UPLOAD_DUPLICATED_FILE_MSG: 'Duplicate File Found',
    UPLOAD_DRAG_N_DROP_MSG: 'Drag and Drop a File',
    UPLOADING_MSG: 'Uploading...',
    WAITING_FOR_UPLOAD_MSG: 'Waiting for upload',
    UPLOAD_CHOOSE_A_FILE: 'or choose a file…',
    FORM_VERSION_EMPTY_MSG: 'Please fill a version',
}