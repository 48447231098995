import { Injectable } from '@angular/core'
import { HttpClient,HttpEventType,HttpResponse,HttpRequest, HttpErrorResponse } from '@angular/common/http'
import { BASE_URL,MANAO_WEB_BASE_URL, SERVICE_API,httpOptions,manaoHttpOptions } from '../../app/constants.js'
import { AngularFirestoreCollection, AngularFirestore } from 'angularfire2/firestore';
import { Observable,Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = BASE_URL
  manao_web_url = MANAO_WEB_BASE_URL
  constructor(private http: HttpClient, private db: AngularFirestore) { }

  getUserList(page,limit,orderBy,sortBy,item) {
    
    var params = {
      page: page,
      limit: limit,
      orderBy: orderBy,
      sortBy: sortBy,
      item: item
    };
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.url + SERVICE_API.getUserList,params,httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          }
        )
        .catch((err) => {
          reject(err)
        })
    })
    return promise
  }

  getAllUsers(): AngularFirestoreCollection<any> {
    return this.db.collection('/user');
  }

  getUserInvitationList(page,limit,orderBy,sortBy,item) {
    var params = {
      page: page,
      limit: limit,
      orderBy: orderBy,
      sortBy: sortBy,
      item: item
    };
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.url + SERVICE_API.getInvitationList,params,httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          }
        )
        .catch((err) => {
          reject(err)
        })
    })
    return promise
  }

  sendInvitationMail(email) {
    var params = {
      email: email
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.url + SERVICE_API.sendInvitationMail, params,httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          }
        ).catch((err) => {
          reject(err)
        })
    })
    return promise
  }

  removeInvitation(invitedId) {
    var params = {
      invitedId: invitedId
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.url + SERVICE_API.removeInvitation, params,httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          }
        ).catch((err) => {
          reject(err)
        })
    })
    return promise
  }

  changeUserStatus(userId) {
    var params = {
      userId: userId
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.url + SERVICE_API.changeUserStatus, params,httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          }
        ).catch((err) => {
          reject(err)
        })
    })
    return promise
  }

  removeUser(userId) {
    var params = {
      userId: userId
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.url + SERVICE_API.removeUser, params,httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          }
        ).catch((err) => {
          reject(err)
        })
    })
    return promise
  }

  uploadInstaller(isIOS,uploadDetails,complete,error): Observable<number>  {
    let api_url = isIOS ? SERVICE_API.uploadIOSInstaller : SERVICE_API.uploadAndroidInstaller
    console.log(api_url)
    const formData: FormData = new FormData();
    formData.append('projectId', uploadDetails.projectId);
    formData.append('platform', uploadDetails.platform);
    formData.append('version', uploadDetails.version);
    if(isIOS) {
      formData.append('ipaFile', uploadDetails.ipaFile, uploadDetails.ipaFile.name);
      formData.append('plistFile', uploadDetails.plistFile, uploadDetails.plistFile.name);
    }else {
      formData.append('apkFile', uploadDetails.apkFile, uploadDetails.apkFile.name);
    }
    const req = new HttpRequest('POST',this.manao_web_url + api_url,formData,manaoHttpOptions);


    const progress = new Subject<number>();

    this.http.request(req)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
          complete(event)
        }
        else if (event instanceof HttpErrorResponse) {
          progress.complete();
          error(event.message)
        }
      })
      return progress.asObservable()
  }
  
}
