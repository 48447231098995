import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { AddProjectService } from './add-project.service';
import { RouterModule, Routes, Router } from '@angular/router';
import {
  NgbModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap"
//Constants
import { httpStatus } from '../../app/constants.js';
import { map, finalize } from "rxjs/operators";
import { AppComponent } from '../app.component';
import { AuthService } from '../modules/core/services/auth/auth.service';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
  modalOptions: NgbModalOptions = { backdrop: "static", size: "lg" }
  modalReference: any;
  userData = {};
  //Initial variables
  model = {
    projectTitle: '',
    description: '',
    isIOS: false,
    isAndroid: false,
    sharedAll: false,
    grant_uid: []
  }

  warningMessage = {
    requiredName: false,
    requiredPlatform: false,
    requiredSharedAll: false
  }

  addProgressing = false;

  //File upload
  fileToUpload: File = null;
  ref: any;
  task: any;
  uploadProgress: any;

  @Input()
  test: any;

  constructor(private addProjectService: AddProjectService, private router: Router, private appComponent: AppComponent
    , private location: Location, private authService: AuthService, private modalService: NgbModal) { }

  ngOnInit() {
    this.userData = this.authService.getUserInformation();
  }

  onSubmitAddProject(data) {
    data.grant_uid = [{ uid: this.userData['uid'], username: this.userData['username'] }];
    console.log(data);
    if (this.model.projectTitle == '' || (!this.model.isIOS && !this.model.isAndroid)) {
      if (this.model.projectTitle == '') {
        this.warningMessage.requiredName = true;
      }

      if (!this.model.isIOS && !this.model.isAndroid) {
        this.warningMessage.requiredPlatform = true;
      }

      // if (this.model.sharedAll == false) {
      //   this.warningMessage.requiredSharedAll = true;
      // }
    } else {
      this.addProgressing = true;
      this.addProjectService.addProject(data).then((res) => {
        console.log(res);
        if (res['status'] == httpStatus.success) {
          this.uploadProjectIcon(res['pid']);
        } else {
          alert(res['status']);
        }
      })
        .catch(err => {
          this.addProgressing = false;
          this.appComponent.alertErrorMessage(err.error.message);
        });
    }
  }

  selectPlatform(platform) {
    if (platform == 'ios') {
      this.model.isIOS = !this.model.isIOS;
    } else {
      this.model.isAndroid = !this.model.isAndroid;
    }
  }

  isSharedAll() {
    this.model.sharedAll = !this.model.sharedAll;
    console.log(this.model.sharedAll);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
  }

  uploadProjectIcon(pid) {
    if (this.fileToUpload) {
      this.ref = this.addProjectService.uploadProjectIcon(pid, this.fileToUpload);
      this.task = this.ref.put(this.fileToUpload).then(() => {
        let iconPath = '';
        let subscribeUrl = this.ref.getDownloadURL().subscribe(url => {
          iconPath = url;
          //Update project icon to database
          this.updateProjectIconPath(pid, iconPath);
          subscribeUrl.unsubscribe();
        })
      });
    } else {
      this.updateProjectIconPath(pid, '');
    }
  }

  updateProjectIconPath(pid, downloadURL) {
    console.log(pid);
    const data = {
      iconUrl: downloadURL
    }
    let query = this.addProjectService.updateProjectIconPath(pid, data)
      .then(res => {
        // this.router.navigate(['projectList']);
        this.closeModal();
        window.location.reload();
      })
      .catch(err => {
        this.appComponent.alertErrorMessage(err);
      });
  }

  openModal(content) {
    this.modalReference = this.modalService.open(content, this.modalOptions)
  }

  closeModal(){
    this.modalReference.close();
  }
}
