import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { UserListComponent } from './user-list/user-list.component';
import { SetupPasswordComponent } from './setup-password/setup-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ErrorPageComponent } from './error-page/error-page.component'
import { RouteGuard } from './modules/core/services/guard/route-guard';
import { UserInvitationListComponent } from './user-invitation-list/user-invitation-list.component';
import { SetupPasswordGuard } from './modules/core/services/guard/setup-password-guard';
import { InvalidUrlGuard } from './modules/core/services/guard/invalid-url-guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordGuard } from './modules/core/services/guard/forgot-password-guard';
import { LoginGuard } from './modules/core/services/guard/login-guard';
import { ProjectDetailGuard } from './modules/core/services/guard/project-detail-guard';
import { UploadFormComponent } from './upload-form/upload-form.component';

const routes: Routes = [
  { path: 'projectList', component: ProjectListComponent, canActivate: [RouteGuard] },
  { path: 'projectList/projectDetail/:id', component: ProjectDetailComponent, canActivate: [ProjectDetailGuard] },
  { path: 'addProject', component: AddProjectComponent, canActivate: [RouteGuard] },
  { path: 'projectList/projectDetail/uploadForm/:platform/:id/:projectName', component: UploadFormComponent, canActivate: [RouteGuard] },
  { path: 'projectList/addProject', component: AddProjectComponent, canActivate: [RouteGuard] },
  { path: 'userList', component: UserListComponent, canActivate: [RouteGuard] },
  { path: 'userInvitationList', component: UserInvitationListComponent, canActivate: [RouteGuard] },
  { path: 'setupPassword', component: SetupPasswordComponent, canActivate: [InvalidUrlGuard] },
  { path: 'setupPassword/:id', component: SetupPasswordComponent, canActivate: [SetupPasswordGuard] },
  { path: 'login', component: SignInComponent, canActivate: [LoginGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [ForgotPasswordGuard]},
  { path: 'error-page', component: ErrorPageComponent},
  // { path: '*/', component: SignInComponent},
  { path: '**', redirectTo: 'login' }
  // {
  //   path: '**',
  //   redirectTo: 'signin',
  //   pathMatch: 'full'
  // }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [],
  exports: [ RouterModule ]
})

export class AppRoutingModule { 
  
}
