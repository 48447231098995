import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AppSettings {
  private showNavbar: boolean = false
  public showNavbarObservable = new Subject<boolean>()

  constructor() {}

  getObservableIsShowNavbar(): Observable<boolean> {
    return this.showNavbarObservable
  }

  setShowNavbar(value: boolean){
    this.showNavbar = value
    this.showNavbarObservable.next(this.showNavbar)
  }

  isShowNavbar(): boolean{
    return this.showNavbar
  }
}