import {
  Component,
  OnInit,
  Input,
} from "@angular/core"
import { UserService } from '../../user-services/user.service'
import { NgbModal, NgbActiveModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap"

@Component({
  selector: 'app-delete-invitation-user-modal',
  templateUrl: './delete-invitation-user-modal.component.html',
  styleUrls: ['./delete-invitation-user-modal.component.css']
})
export class DeleteInvitationUserModalComponent implements OnInit {

  deleteUserMsg:string
  isLoading = false
  isDeleteUserError = false
  isDeleteUserSuccess = false
  @Input() user: any
  
  modalOptions: NgbModalOptions = { backdrop:'static',windowClass:'animated slideInUp' }
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private userService: UserService
  ) {}

  ngOnInit() {
  }
  //Firebase SDK
  openModal(content) {
    this.deleteUserMsg = null
    this.activeModal = this.modalService.open(content,this.modalOptions)
  }

  removeInvitation() {
    this.isLoading = true
    this.userService
      .removeInvitation(this.user.userInvId)
      .then(data => {
        // this.projectList = data['status']
        console.log(data["status"])
        this.activeModal.close()
        this.isLoading = false
        this.isDeleteUserError = false
        this.isDeleteUserSuccess = true
        this.deleteUserMsg = data["message"]
        // this.isLoading = data['status']  == 'SUCCESS' ? false : true
      })
      .catch(err => {
        this.isLoading = false
        this.isDeleteUserError = true
        this.deleteUserMsg = err.error && err.error.message
      })
  }

}
