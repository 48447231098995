import { Component, OnInit, ViewChild, ViewEncapsulation, Directive } from '@angular/core';
//Service
import { ProjectListService } from './project-list-service.service';
import { Router } from "@angular/router";
//Router
import { ActivatedRoute } from "@angular/router";
import { AppComponent } from '../app.component';
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from '../modal/delete-project/confirm-modal.component';
//Constants
import { httpStatus, PLATFORM } from '../../app/constants.js';
import { AppSettings } from '../modules/core/services/settings';
import { EventEmitter } from 'events';
import { AuthService } from '../modules/core/services/auth/auth.service';

export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ProjectListComponent implements OnInit {

  @ViewChild(ConfirmModalComponent) child;
  modalOptions: NgbModalOptions = { backdrop: "static", size: "lg" }
  modalReference: any;
  //Device Info
  deviceInfo = {
    info: {},
    device: {}
  };
  //User data
  userData: any;
  userRole: any;
  displayName: any;
  platform = PLATFORM;
  //loading
  loading = {
    loadingData: false,
    deleteProgressing: false,
    changeStatusLoading: false
  }
  //Project Lists
  projectList = [];
  projectCount = 0;
  startItem: any;
  lastItem: any;
  //Sort
  sortBy = "status.updated_time";
  orderBy: any = "desc";
  //Paging
  page = 1;
  limit = 10;
  index = this.limit;
  indexItem = [];
  isUpdateTable = false;
  totalPage = 0;
  //Warning mesg
  accessDeny = false;

  constructor(private router: Router, private modalService: NgbModal, public activeModal: NgbActiveModal,
    private route: ActivatedRoute, private appComponent: AppComponent, private projectListService: ProjectListService, private settings: AppSettings, private authService: AuthService) {
    //console.log(authService.getUserInformation());
    this.userData = authService.getUserInformation();
    this.userRole = this.userData.role;
    //console.log(this.userRole);
    var detectDevice = appComponent.getDetectDevice();
    this.deviceInfo = {
      info: detectDevice,
      device: detectDevice['device']
    };
    //console.log(this.deviceInfo)
  }

  ngOnInit() {
    this.settings.setShowNavbar(true)
    this.indexItem = [];
    this.page = 1;
    this.index = this.limit;
    this.isUpdateTable = false;
    this.accessDeny = false;
    this.getProjectList(this.sortBy, this.orderBy, false, this.limit, undefined, this.page, this.userData.uid);
  }

  getProjectList(sortBy, orderBy, isNext, limit, lastItem, page, uid) {
    //web browser
    let device = this.deviceInfo.device == 'unknown' ? undefined : this.deviceInfo.device;
    if (device !== 'android' && device !== undefined) {
      device = PLATFORM.ios; //iphone, ipad
    }
    this.loading.loadingData = true;
    this.projectListService.getProjectList(sortBy, orderBy, limit, lastItem, page, device, uid).then(res => {
      console.log(res);
      let items = [];
      if (res['status'] == httpStatus.success) {
        this.getProjectCount();
        items = res['item'];
        this.projectList = items;

        if (this.projectList.length !== 0) {
          if (this.indexItem.length == 0) {
            this.indexItem.push({
              page: this.page,
              startItem: items[0].data,
              lastItem: items[items.length - 1].data
            });
          } else if (isNext) {
            if (this.indexItem.map((item) => { return item.page }).indexOf(this.page) == -1) {
              this.indexItem.push({
                page: this.page,
                startItem: items[0].data,
                lastItem: items[items.length - 1].data
              });
            }
          }
        }
        this.loading.loadingData = false;
      } else {
        this.appComponent.alertErrorMessage(res['message']);
        this.loading.loadingData = false;
      }
    })
    // console.log(this.indexItem);
  }

  getProjectCount() {
    let deviceInfo = this.deviceInfo.device;
    let totalProject = this.projectListService.getProjectCount();
    let temp = this.projectCount;
    totalProject.valueChanges().subscribe(snapshots => {
      if (deviceInfo == 'unknown') {
        this.projectCount = snapshots['projectCount'];
      } else if (deviceInfo == 'android') {
        this.projectCount = snapshots['projectMobileCount']['android'];
      } else {
        this.projectCount = snapshots['projectMobileCount']['iOS'];
      }
      this.totalPage = this.projectCount % this.limit == 0 ? this.projectCount / this.limit : (this.projectCount / this.limit | 0) + 1;
      // console.log(this.projectCount, this.totalPage);
    })
  }

  progressStatus($event) {
    let isTrueSet = ($event == 'true');
    this.loading.deleteProgressing = isTrueSet;
    if (this.loading.deleteProgressing == false) {
      //false means finished deleting 
      //but setting to true because to show the progress bar until the page is reloaded.
      this.loading.deleteProgressing = true;
      this.snapshotChangesTable('delete');
    }
  }

  snapshotChangesTable(changedType) {
    let start: any;
    let last: any;

    let query = this.projectListService.snapshotChangesTable(this.sortBy, this.orderBy, this.limit, this.page).valueChanges().subscribe(items => {
      if (changedType == 'update' && this.sortBy !== 'sort_name') {
        this.isUpdateTable = true;
      } else {
        this.isUpdateTable = false;
        if (this.projectList.length == 1) {
          this.page--; //page-- effect to setting start/last items
        }
      }
      query.unsubscribe();
      this.setNewStartLastItems(items, changedType);
    })
  }

  setNewStartLastItems(tempList, changedType) {
    console.log(tempList, this.totalPage);
    //clear indexItem
    this.indexItem = [];

    if (tempList.length > 0) {
      for (let i = 1; i <= this.page; i++) {

        //Start-Last of each page
        let startIndex = (this.limit * i) - this.limit;
        let lastIndex = 0;

        //Getting last index of last page
        if (i == this.page) {
          lastIndex = tempList.length - 1;
        } else {
          lastIndex = (startIndex + this.limit) - 1;
        }

        let startObject = tempList[startIndex];
        let lastObject = tempList[lastIndex];

        // Convert timestamp to ISO string (Because firebase return updated time as Timestamp (seconds and nanoseconds))
        if (typeof startObject.status.updated_time !== 'string') {
          startObject.status.updated_time = new Date(startObject.status.updated_time.seconds * 1000).toISOString();
        }

        if (typeof lastObject.status.updated_time !== 'string') {
          lastObject.status.updated_time = new Date(lastObject.status.updated_time.seconds * 1000).toISOString();
        }

        this.indexItem.push({
          page: i,
          startItem: tempList[startIndex],
          lastItem: tempList[lastIndex]
        });

        if (i == this.page) {
          if (changedType == 'delete') {
            console.log(this.indexItem);
            let lastPrev = {};
            if (this.page !== 1) {
              lastPrev = this.indexItem[i - 2].lastItem.status.updated_time;
            } else {
              lastPrev = undefined;
            }
            this.getProjectList(this.sortBy, this.orderBy, false, this.limit, lastPrev, this.page, this.userData.uid);
            this.loading.deleteProgressing = false;
          }
        }
      }
    } else if (tempList.length == 0) {
      this.page = 1;
      this.getProjectList(this.sortBy, this.orderBy, false, this.limit, undefined, this.page, this.userData.uid);
      this.loading.deleteProgressing = false;
    }
  }

  onPrev() {
    this.index -= this.limit;
    this.page--;

    let start = {};
    let item = this.indexItem[0];

    if (this.isUpdateTable == true) {
      this.removeTaost();
    }

    //false means Prev
    if (this.page == 1) {
      switch (this.sortBy) {
        case "sort_name":
          start = item.startItem.sort_name;
          break;
        case "status.isActivated":
          start = {
            updatedTime: item.startItem.status.updated_time,
            isActivated: item.startItem.status.isActivated
          }
          break;
        case "status.updated_time":
          start = item.startItem.status.updated_time;
          break;
      }

      this.getProjectList(this.sortBy, this.orderBy, false, this.limit, start, this.page, this.userData.uid);
      // console.log("prev - startafter: ", this.indexItem[0].lastItem.name);
    } else {
      let last = {};
      let item = this.indexItem[this.page - 2];
      switch (this.sortBy) {
        case "sort_name":
          last = item.lastItem.sort_name;
          break;
        case "status.isActivated":
          last = {
            updatedTime: item.lastItem.status.updated_time,
            isActivated: item.lastItem.status.isActivated
          };
          break;
        case "status.updated_time":
          last = item.lastItem.status.updated_time;
          break;
      }

      // console.log("prev - startafter: ", this.indexItem[this.page - 2].lastItem.name);
      this.getProjectList(this.sortBy, this.orderBy, false, this.limit, last, this.page, this.userData.uid);
    }
  }

  onNext() {
    this.index += this.limit;
    this.page++;
    //true means isNext
    let last = {};
    let item = this.indexItem[this.page - 2];

    //Clear taost
    if (this.isUpdateTable == true) {
      this.removeTaost();
    }

    switch (this.sortBy) {
      case "sort_name":
        last = item.lastItem.sort_name;
        break;
      case "status.isActivated":
        last = {
          updatedTime: item.lastItem.status.updated_time,
          isActivated: item.lastItem.status.isActivated
        };
        break;
      case "status.updated_time":
        last = item.lastItem.status.updated_time
        break;
    }

    // console.log("next - startafter: ", item.lastItem.name);
    this.getProjectList(this.sortBy, this.orderBy, true, this.limit, last, this.page, this.userData.uid);

  }

  updateOrderField(field) {
    let prevSortBy = this.sortBy;
    this.sortBy = field;
    if (this.sortBy == 'sort_name') {
      if (this.sortBy == prevSortBy) {
        this.orderBy = this.orderBy == "asc" ? "desc" : "asc";
      } else {
        this.orderBy = "asc";
      }

    } else {
      if (this.sortBy == prevSortBy) {
        this.orderBy = this.orderBy == "desc" ? "asc" : "desc";
      } else {
        this.orderBy = "desc";
      }
    }
    this.ngOnInit();
  }

  changeProjectStatus(pid, index) {
    this.loading.changeStatusLoading = true;
    this.projectListService.changeProjectStatus(pid).then((res) => {
      console.log(res);
      this.snapshotChangesTable('update');
      if (res['status'] == httpStatus.success) {
        this.projectList[index].data.status.isActivated = !this.projectList[index].data.status.isActivated;
        this.loading.changeStatusLoading = false;
      } else {
        this.appComponent.alertErrorMessage(res['message']);
        this.loading.changeStatusLoading = false;
      }
    })
      .catch((error) => {
        console.log(error);
        this.loading.changeStatusLoading = false;
        this.appComponent.alertErrorMessage(error.error.message + " Please reload this page.");
      });
  }

  removeTaost() {
    this.isUpdateTable = false;
  }

  goToProjectDetail(list, content) {
    let data = list.data;
    console.log(list);
    
    if (!data.sharedAll) {
      let index = data.grant_uid.map(function (e) { return e.uid; }).indexOf(this.userData.uid);

      if (index !== -1) {
         this.router.navigate(['/projectList/projectDetail/' + list.data.id]);
         // this.modalReference = this.modalService.open(content, this.modalOptions)
      } else {
        this.accessDeny = true;
      }
    } else {
      this.router.navigate(['/projectList/projectDetail/' + list.data.id]);
    }

  }
}

