import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFirestore } from "angularfire2/firestore";

@Injectable()
export class SetupPasswordGuard implements CanActivate {

  constructor(public router: Router, public afAuth: AngularFireAuth, private afs: AngularFirestore) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    console.log("route-access",state);
    console.log(route.url[1].path)
    const invitationId = route.url[1].path;
    return new Promise((resolve, reject) => {
        if(invitationId){
            this.afs.collection('invitation').doc(invitationId).ref.get().then(invitation => {
                console.log(invitation)
                if (invitation.exists) {
                    console.log("Document data:", invitation.data());
                    resolve(true)
                    return true;
                } else {
                    console.log("No such document!");
                    this.router.navigate(['/error-page'])
                    resolve(false)
                    return false;
                }
            })
            .catch(err => {
                console.log(err);
                this.router.navigate(['/error-page'])
                resolve(false)
                return false;
            })
        }
        else{
            console.log('not found invitation id')
            this.router.navigate(['/error-page'])
            resolve(false)
                return false;
        }
        
    })
  }
}