import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '../../app/constants.js';
//FireStore
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';

@Injectable({
  providedIn: 'root'
})
export class AddProjectService {

  constructor(private http: HttpClient, private db: AngularFirestore, private afStorage: AngularFireStorage) { }

  addProject(params){
    console.log(params);
    
    let promise = new Promise((resolve, reject) => {
      let apiURL = BASE_URL + 'addNewProject';
      this.http.post(apiURL, params)
        .toPromise()
        .then(
          res => { // Success
            // console.log(res);
            resolve(res);
          },
          err => {
            reject(err);
            console.log(err);
          }
        );
    });
    return promise;
  }

  updateProjectIconPath(pid, data){
    return this.db.collection('/project').doc(pid).update(data)
  }

  uploadProjectIcon(pid, fileToUpload){
    return this.afStorage.ref(pid + '/Assets/Icons/' + fileToUpload.name)
  }
}
