import { Component, OnInit } from "@angular/core"
//Firebase
import { AngularFirestore } from "angularfire2/firestore"
import { AppComponent } from "../app.component"
import { AppSettings } from "../modules/core/services/settings"
import { httpStatus } from "../../app/constants.js"
import { UserService } from "../user-services/user.service"
import { NgxSpinnerService } from "ngx-spinner"
export interface UserInvitationList {
  uid: string
  email: string
  invitationTime: number
}

@Component({
  selector: "app-user-invitation-list",
  templateUrl: "./user-invitation-list.component.html",
  styleUrls: ["./user-invitation-list.component.css"]
})
export class UserInvitationListComponent implements OnInit {
  //constants
  ORDER_EMAIL_FIELD = "sort_email"
  ORDER_INVITATION_TIME_FIELD = "invitationTime"
  ORDER_ASC = "asc"
  ORDER_DESC = "desc"

  userCount = 0
  page = 1
  limit = 10
  totalPage = 0
  startItem: any
  lastItem: any
  index = this.limit
  userRole: any
  displayName: any
  closeResult: string
  userInvitationList: any
  indexItem = []
  orderBy: any = this.ORDER_DESC
  sortBy = this.ORDER_INVITATION_TIME_FIELD
  loadingData = false
  isUpdateTable = true

  constructor(
    private db: AngularFirestore,
    private appComponent: AppComponent,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private settings: AppSettings
  ) {
    this.displayName = appComponent.getDisplayName()
    this.userRole = appComponent.getUserRole()
    this.snapshotChangesTable()
  }

  ngOnInit() {
    this.indexItem = []
    this.page = 1
    this.index = this.limit
    this.isUpdateTable = false
    this.settings.setShowNavbar(true)
    this.getInvitationList(
      this.page,
      this.limit,
      this.orderBy,
      this.sortBy,
      undefined,
      false
    )
  }

  //Firebase SDK
  getInvitedUserCount() {
    var totalUser = this.db.doc("information/invitation")
    totalUser.valueChanges().subscribe(snapshots => {
      this.userCount = snapshots["invitedCount"]
      this.totalPage = this.userCount % this.limit == 0 ? this.userCount / this.limit : (this.userCount / this.limit | 0) + 1
    })
  }

  getInvitationList(page, limit, orderBy, sortBy, item, isNext) {
    this.loadingData = true
    this.userService
      .getUserInvitationList(page, limit, orderBy, sortBy, item)
      .then(res => {
        let items = []
        if (res["status"] == httpStatus.success) {
          res["item"].forEach(el => items.push(el.data as UserInvitationList))
          this.userInvitationList = items
          this.getInvitedUserCount()
          if (this.indexItem.length == 0) {
            this.indexItem.push({
              page: this.page,
              startItem: items[0],
              lastItem: items[items.length - 1]
            })
          } else if (isNext) {
            if (
              this.indexItem
                .map(item => {
                  return item.page
                })
                .indexOf(this.page) == -1
            ) {
              this.indexItem.push({
                page: this.page,
                startItem: items[0],
                lastItem: items[items.length - 1]
              })
            }
          }
          this.loadingData = false
        } else {
          this.appComponent.alertErrorMessage(res["message"])
          this.loadingData = false
        }
      })
  }

  snapshotChangesTable() {
    this.db
      .collection("invitation")
      .stateChanges()
      .subscribe(items => {
        items.forEach(el => {
          if (el.type === "added" && !this.loadingData) {
            document.getElementById("toast").className = "show"
          } else if (el.type === "modified") {
            let changedItem = el.payload.doc.data()
            let adjustedChangedItem = {
              ...changedItem,
              invitationTime: new Date(
                changedItem[this.ORDER_INVITATION_TIME_FIELD].seconds * 1000
              ).toISOString()
            }
            this.userInvitationList = this.userInvitationList.map(
              el =>
                el.uid === changedItem["userInvId"]
                  ? (el = adjustedChangedItem)
                  : el
            )
            if (this.isUpdateTable) {
              document.getElementById("toast").className = "show"
            }
          } else if (el.type === "removed") {
            this.getInvitationList(
              this.page,
              this.limit,
              this.orderBy,
              this.sortBy,
              this.page > 1 ? this.getActualItemByFilterType() : undefined,
              false
            )
          }
        })
      })
  }

  getActualItemByFilterType() {
    if (this.sortBy == this.ORDER_EMAIL_FIELD) {
      return this.indexItem[this.page - 2].lastItem.email
    } else {
      return this.indexItem[this.page - 2].lastItem.invitationTime
    }
  }

  onPrev() {
    if (this.page === 1) {
      return
    }
    document.getElementById("toast").className = ""
    this.index -= this.limit
    this.page--
    this.getInvitationList(
      this.page,
      this.limit,
      this.orderBy,
      this.sortBy,
      this.setItemWhenChangePage(this.indexItem[0], false),
      false
    )
  }

  onNext() {
    if (this.index >= this.userCount || this.userCount < this.index || this.userCount == 0) {
      return
    }
    document.getElementById("toast").className = ""
    this.index += this.limit
    this.page++
    this.getInvitationList(
      this.page,
      this.limit,
      this.orderBy,
      this.sortBy,
      this.setItemWhenChangePage(this.indexItem[this.page - 2], true),
      true
    )
  }

  setItemWhenChangePage(item, isNext) {
    switch (this.sortBy) {
      case this.ORDER_EMAIL_FIELD:
        return isNext ? item.lastItem.email : item.startItem.email
      case this.ORDER_INVITATION_TIME_FIELD:
        return isNext
          ? item.lastItem.invitationTime
          : item.startItem.invitationTime
    }
  }

  updateOrderField(field) {
    let prevSortBy = this.sortBy
    this.sortBy = field
    if (this.sortBy === this.ORDER_EMAIL_FIELD) {
      this.isUpdateTable = false
      if (this.sortBy === prevSortBy) {
        this.orderBy =
          this.orderBy == this.ORDER_ASC ? this.ORDER_DESC : this.ORDER_ASC
      } else {
        this.orderBy = this.ORDER_ASC
      }
    } else {
      this.isUpdateTable = true
      if (this.sortBy === prevSortBy) {
        this.orderBy =
          this.orderBy == this.ORDER_DESC ? this.ORDER_ASC : this.ORDER_DESC
      } else {
        this.orderBy = this.ORDER_DESC
      }
    }
    this.ngOnInit()
  }
}
