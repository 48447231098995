import {
  Component,
  OnInit,
  Input,
} from "@angular/core"
import { NgbModal, NgbActiveModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap"
//Firebase
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "angularfire2/firestore"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
export interface UserList { id: string, username: string, authorized: string, role: string }
export interface RoleList {
  id: string
  role_name: string
}
@Component({
  selector: "app-edit-user-modal",
  templateUrl: "./edit-user-modal.component.html",
  styleUrls: ["./edit-user-modal.component.css"]
})
export class EditUserModalComponent implements OnInit {
  private roleListCollection: AngularFirestoreCollection<RoleList>
  private userCollection: AngularFirestoreCollection<UserList>
  roleListObs: Observable<RoleList[]>
  roleList: RoleList[]
  selectedRole = "Select a role"
  editUserMsg:string
  isLoading = false
  isEditUserError = false
  @Input() user: any
  modalOptions: NgbModalOptions = { backdrop:'static', size: 'lg' }
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private db: AngularFirestore
  ) {
    this.roleListCollection = this.db.collection<RoleList>("role")
    this.userCollection = this.db.collection<UserList>("user")
  }

  ngOnInit() {
    this.getRoleList()
    this.roleListObs.subscribe(role => {
      this.roleList = role
    })
  }
  openModal(content) {
    this.editUserMsg = null
    this.selectedRole = this.user.role && this.user.role
    this.activeModal = this.modalService.open(content,this.modalOptions)
  }

  selectorChangeEvent(changedRole) {
    this.editUserMsg = null
    this.selectedRole = changedRole
  }

  //Firebase SDK
  getRoleList() {
    this.roleListObs = this.roleListCollection
      .snapshotChanges(["added", "modified", "removed"])
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as RoleList
            const id = a.payload.doc.id
            return { id, ...data }
          })
        )
      )
  }

  editUserRole() {
    this.isLoading = true
    this.editUserMsg = null
    this.userCollection.doc(this.user.uid).update({role:this.selectedRole}).then(() => {
      this.activeModal.close()
      this.editUserMsg = "Success"
      this.isLoading = false
      this.isEditUserError = false
    })
    .catch((err) => {
      this.editUserMsg = err
      this.isLoading = false
      this.isEditUserError = true
    })
  }
}
