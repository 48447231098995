import { Component, OnInit } from "@angular/core"
import { UserService } from '../../user-services/user.service'
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap"
@Component({
  selector: "app-invite-user-modal",
  templateUrl: "./invite-user-modal.component.html",
  styleUrls: ["./invite-user-modal.component.css"]
})
export class InviteUserModalComponent implements OnInit {
  modalOptions: NgbModalOptions = { backdrop: "static", size: "lg" }
  isInviteUserError = false
  inviteUserMsg: string
  isLoading = false
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private userService: UserService
  ) {}

  ngOnInit() {}
  openModal(content) {
    this.inviteUserMsg = null
    this.modalService.open(content, this.modalOptions)
  }
  sendInvitationMail(email) {
    let trimmedEmail = email.trim()
    this.isLoading = true
    this.inviteUserMsg = null
    if (!trimmedEmail) {
      this.isLoading = false
      this.isInviteUserError = true
      this.inviteUserMsg = "Please enter an email."
      return
    }else if (!this.validateEmail(trimmedEmail)) {
      this.isLoading = false
      this.isInviteUserError = true
      this.inviteUserMsg = "Invalid Email"
      return
    }
    this.userService
      .sendInvitationMail(trimmedEmail)
      .then(data => {
        console.log(data["status"])
        this.isLoading = false
        this.isInviteUserError = false
        this.inviteUserMsg = data["message"]
        // this.isLoading = data['status']  == 'SUCCESS' ? false : true
      })
      .catch(err => {
        this.isLoading = false
        this.isInviteUserError = true
        this.inviteUserMsg = err.error && err.error.message
      })
  }
  //Ext.

  onKeydownEmailInput() {
    this.isInviteUserError = false
    this.inviteUserMsg = null
  }

  private validateEmail(email) {
    var re = /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }
}
