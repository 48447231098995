import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from "angularfire2/auth";

@Injectable()
export class RouteGuard implements CanActivate {

  constructor(public router: Router, public afAuth: AngularFireAuth) {}

  public canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
        this.afAuth.authState.subscribe(user => {
            if (user){
                resolve(true);
                return true
            }
            else{
                this.router.navigate(['login']);
                resolve(true);
                return false
            }
            
        })
    })
  }
}