import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../modules/core/services/settings';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {

  constructor(public router: Router, private settings:AppSettings) { }

  ngOnInit() {
     this.settings.setShowNavbar(false)
  }

  navigateToHome(){
    this.router.navigate(['/projectList'])
  }

}
