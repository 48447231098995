import { Component, OnInit } from "@angular/core"
import { SetupPasswordService } from "./setup-password.service"
import { Router, ActivatedRoute, Params } from "@angular/router"
import { NgxSpinnerService } from "ngx-spinner"
//Firebase
import { AngularFirestore } from "angularfire2/firestore"
import { AppSettings } from "../modules/core/services/settings";

@Component({
  selector: "app-setup-password",
  templateUrl: "./setup-password.component.html",
  styleUrls: ["./setup-password.component.css"]
})
export class SetupPasswordComponent implements OnInit {
  closeResult: string
  isLoading = false
  setUpPasswordErrMsg: string
  confirmSetUpPasswordErrMsg: string
  passwordChangeVal: string
  confirmPasswordChangeVal: string
  invitationId: string
  isSetupPasswordMsg: string

  constructor(
    private setupPasswordService: SetupPasswordService,
    private router: Router,
    private db: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService, 
    private settings:AppSettings
  ) {
    this.invitationId = this.activatedRoute.snapshot.paramMap.get("id")
  }
  ngOnInit() {
    this.settings.setShowNavbar(false)
  }

  passwordChange(text) {
    this.passwordChangeVal = text
    this.clearErrMsg()
  }
  confirmPasswordChange(text) {
    this.confirmPasswordChangeVal = text
    this.clearErrMsg()
  }

  savePassword() {
    this.isLoading = true
    this.isSetupPasswordMsg = null
    if (!this.passwordChangeVal) {
      this.setUpPasswordErrMsg = "This field is required."
    }
    if (!this.confirmPasswordChangeVal) {
      this.confirmSetUpPasswordErrMsg = "This field is required."
    }
    if (this.passwordChangeVal && this.passwordChangeVal.length < 6) {
      this.setUpPasswordErrMsg = "Must be 6-20 characters long."
    }
    if (
      this.confirmPasswordChangeVal &&
      this.confirmPasswordChangeVal.length < 6
    ) {
      this.confirmSetUpPasswordErrMsg = "Must be 6-20 characters long."
    } else if (
      this.confirmPasswordChangeVal &&
      this.passwordChangeVal &&
      this.confirmPasswordChangeVal !== this.passwordChangeVal
    ) {
      this.confirmSetUpPasswordErrMsg = "Password Mismatch."
    }
    if(this.setUpPasswordErrMsg || this.confirmSetUpPasswordErrMsg) {
      this.isLoading = false
      return
    }
    this.spinner.show()
    this.setupPasswordService
      .savePassword(this.invitationId, this.confirmPasswordChangeVal)
      .then(data => {
        this.spinner.hide()
        this.isLoading = false
        this.router.navigate(["/"])
      })
      .catch(err => {
        this.spinner.hide()
        this.isLoading = false
        this.isSetupPasswordMsg = err.error && err.error.message
      })
  }

  clearErrMsg() {
    this.setUpPasswordErrMsg = null
    this.confirmSetUpPasswordErrMsg = null
    this.isSetupPasswordMsg = null
  }
}
