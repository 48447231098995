import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BASE_URL, SERVICE_API, httpOptions, MANAO_WEB_BASE_URL } from '../../app/constants.js';
//Firebase
import { AngularFirestore } from 'angularfire2/firestore';

export interface List { id: string; name: string; }

@Injectable({
  providedIn: 'root'
})
export class ProjectListService {

  constructor(private http: HttpClient, private db: AngularFirestore) { }

  getProjectList(sortBy, orderBy, limit, lastItem, page, platform, uid) {
    var params = {
      sortBy: sortBy,
      orderBy: orderBy,
      limit: limit,
      item: lastItem,
      page: page,
      platform: platform,
      uid: uid
    };

    let path = platform == undefined ? SERVICE_API.getProjectList : SERVICE_API.getProjectListForMobile; 

    console.log(BASE_URL + path, params);
    let promise = new Promise((resolve, reject) => {
      this.http.post(BASE_URL + path, params, httpOptions)
        .toPromise()
        .then(
          res => {
            // Success
            // console.log(res);
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getProjectCount(){
    return this.db.doc('information/project')
  }

  snapshotChangesTable(sortBy, orderBy, limit, page){
    return this.db.collection('/project', ref => ref.orderBy(sortBy, orderBy).limit(limit * page))
  }

  removeProject(pid) {
    var params = {
      projectId: pid
    };

    console.log(params);

    let promise = new Promise((resolve, reject) => {
      this.http.post(BASE_URL + SERVICE_API.removeProject, params, httpOptions)
        .toPromise()
        .then(
          res => {
            // Success
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  removeProjectAsset(pid) {
    var params = {
      projectId: pid
    };

    console.log(params);

    let promise = new Promise((resolve, reject) => {
      this.http.post(MANAO_WEB_BASE_URL + SERVICE_API.removeProjectAsset, params, httpOptions)
        .toPromise()
        .then(
          res => {
            // Success
            resolve(res);
          },
          err => {
            console.log(err);
            
            reject(err);
          }
        );
    });
    return promise;
  }

  changeProjectStatus(pid) {

    var params = {
      projectId: pid
    };

    let promise = new Promise((resolve, reject) => {
      this.http.post(BASE_URL + SERVICE_API.changeProjectStatus, params, httpOptions)
        .toPromise()
        .then(
          res => {
            // Success
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }
}
