import { Component, OnInit } from '@angular/core';
import { ForgotPasswordModel } from './forgot-password.model';
import { AngularFireAuth } from 'angularfire2/auth';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { AppSettings } from '../modules/core/services/settings';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordModel = new ForgotPasswordModel('')
  errorMessage = ''
  submitted = false;

  constructor(public settings:AppSettings, public afAuth: AngularFireAuth, private toastr: ToastrService, private spinner: NgxSpinnerService, public router: Router) { }

  ngOnInit() {
    this.settings.setShowNavbar(false)
    this.loadScript();
  }

  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/forgot-password.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  showEmailError(message){
    this.errorMessage = message
  }

  clearEmailError(){
    this.errorMessage = ''
  }

  onSubmit() { 
    const email = this.forgotPasswordModel.email.trim()
    if(this.submitted){ return }

    if(!this.validateField(email)){ return }

    // this.clearEmailError();
    this.submitted = true; 
    this.forgotPassword(email);
  }

  forgotPassword(email){
    this.spinner.show();
    this.afAuth.auth.sendPasswordResetEmail(email).then(result => {
      this.forgotPasswordModel.email = ''
      this.submitted = false; 
      this.spinner.hide();
      this.router.navigate(['/login'])
    }).catch(err => {
      this.forgotPasswordModel.email = ''
      this.submitted = false; 
      this.errorMessage = err.message
      this.spinner.hide();
    })
  }

  validateField(email): boolean{
    let isHaveInvalidField = false;
    if(!email || 0 === email.length || !this.validateEmail(email)){
      this.showEmailError('A valid email is required.')
      isHaveInvalidField = true;
    }

    return !isHaveInvalidField
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
