import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFirestore } from "angularfire2/firestore";

@Injectable()
export class ProjectDetailGuard implements CanActivate {

  constructor(public router: Router, public afAuth: AngularFireAuth, private afs: AngularFirestore) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const projectId = route.url[2].path;
    return new Promise((resolve) => {
        this.afAuth.authState.subscribe(user => {
            if (user){
                this.afs.collection('project').doc(projectId).ref.get().then(snapshot => {
                    // console.log(snapshot)
                    if (snapshot.exists) {
                        // console.log("Document data:", snapshot.data());
                        resolve(true)
                        return true;
                    } else {
                        // console.log("No such document!");
                        this.router.navigate(['/error-page'])
                        resolve(false)
                        return false;
                    }
                })
                .catch(err => {
                    // console.log(err);
                    this.router.navigate(['/error-page'])
                    resolve(false)
                    return false;
                })
            }
            else{
                this.router.navigate(['login']);
                resolve(true);
                return false
            }
        })
    })
  }
}